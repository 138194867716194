import { Image } from "react-bootstrap";

import ascoConferenceImg from "../../../images/banner/ASCO-conference.png";

const TTR_PILS = [
  "Pre Conference Video Ads",
  "Capture KOL/SME testimonials",
  "Record Product Theaters/Workshops",
  "Animated Poster Presentations",
];

export const ASCOAmplificationStrategiesDetail = () => (
  <section className="section-sp1 about-area">
    <h1
      style={{
        textAlign: "center",
        marginBottom: "64px",
        marginLeft: "16px",
        marginRight: "16px",
      }}
    >
      Pre to Post Conference Marketing
    </h1>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 mb-30">
          <div className="heading-bx">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <h6 className="title-ext text-secondary">
                Pre to Post Conference Marketing Solutions
              </h6>
            </div>
            <p>
              Maximize your impact at ASCO with our comprehensive digital and
              video solutions designed to engage and educate Oncology HCPs.
              Utilize pre-conference digital marketing powered by NPI data to
              reach your target audience well before ASCO begins. During the
              conference, we offer strategic digital tactics to drive booth
              traffic, while our on-site video team captures compelling KOL and
              SME testimonials — whether at your booth or an offsite location.
              Post - event, we help re-engage HCPs to ensure your message
              continues to resonate long after ASCO concludes.
            </p>
          </div>
          <div className="row">
            {TTR_PILS.map((ttrItem) => (
              <div key={ttrItem} className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
                <div className="feature-container feature-bx1 feature1">
                  <div className="icon-md">
                    <span className="icon-cell"></span>
                  </div>
                  <div className="icon-content">
                    <h4 className="ttr-title">{ttrItem}</h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-6 mb-30">
          <Image
            style={{
              width: "100%",
              borderRadius: "16px",
            }}
            src={ascoConferenceImg}
            alt="Conference Marketing"
          />
        </div>
        <div className="conference-marketing-bx">
          <div className="conference-marketing-content">
            <h2>Increase Booth Attendance and Booked Meetings</h2>
            <ul>
              <li>• Eye-Catching Booth Loop Videos</li>
              <li>• Event Landing Pages</li>
              <li>• Geofencing Conferences</li>
            </ul>
          </div>
          <div className="conference-marketing-bx-image">
            <Image
              src={
                "https://builtin.com/sites/www.builtin.com/files/styles/og/public/2022-09/biotech.png"
              }
              alt="Rebus Bio Systems"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);
