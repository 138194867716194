import React from "react";

import "./index.scss";
export const CustomerSection = ({ clients }) => {
  return (
    <div className="customer_section">
      <h2
        style={{
          fontWeight: "normal",
        }}
      >
        Our Clients
      </h2>
      <div style={{ height: "48px" }} />
      <div className="customer_section_logo_section">
        {clients?.map((client) => (
          <div className="customer_section_logo_sub_section">
            {client?.client1?.logo && (
              <img
                src={client?.client1?.logo}
                alt={client?.client1?.alt}
                className="customer_section_brand_logo"
                style={{
                  objectFit: "contain",
                }}
              />
            )}
            {client?.client2.logo && (
              <img
                src={client?.client2?.logo}
                alt={client?.client2?.alt}
                className="customer_section_brand_logo"
                style={{
                  objectFit: "contain",
                }}
              />
            )}
          </div>
        ))}
      </div>
      <div style={{ height: "64px" }} />
    </div>
  );
};
