import React, { Component } from "react";
import { CustomerSection } from "../customersSection";
import VimeoEmbed from "../vimeo/vimeo";

import fulgentLogo from "../../../images/logos/fulgent.png";
import labcorpLogo from "../../../images/logos/labcorp.png";
import mdHealthLogo from "../../../images/logos/md-health.png";
import tempusLogo from "../../../images/logos/tempus.png";

const RSNA_CLIENTS = [
  {
    client1: {
      logo: "https://media.graphassets.com/output=format:jpg/resize=height:800,fit:max/ZKOtJtBQ431UgtCWFVFg",
      alt: "Agilent Technologies",
    },
    client2: { logo: tempusLogo, alt: "Tempus AI" },
  },
  {
    client1: { logo: mdHealthLogo, alt: "MD Health" },
    client2: {
      logo: "https://media.graphassets.com/output=format:jpg/resize=height:800,fit:max/jbNVCm0SdG3bPXc3rowA",
      alt: "Natera",
    },
  },
  {
    client1: { logo: labcorpLogo, alt: "Labcorp" },
    client2: { logo: fulgentLogo, alt: "Fulgent" },
  },
];

class ASCOAmplificationStrategiesBanner extends Component {
  render() {
    return (
      <>
        <div className="conference-hero">
          <div className="container inner-content">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-7">
                <h1> ASCO Amplification Strategies</h1>
                <h4 className="title-ext text-primary">
                  Find out how to maximize your ASCO conference ROI with our
                  proven digital advertising and video strategies
                </h4>
              </div>{" "}
              <div className="col-lg-6 col-md-6 col-sm-5">
                {" "}
                <VimeoEmbed />
              </div>
            </div>
          </div>
        </div>
        <CustomerSection clients={RSNA_CLIENTS} />
      </>
    );
  }
}

export default ASCOAmplificationStrategiesBanner;
