import React from "react";

import { ASCOAmplificationStrategiesDetail } from "../elements/asco-amplification-strategies/detail";
import ASCOAmplificationStrategiesBanner from "../elements/asco-amplification-strategies/main-banner";
import ASCOAmplificationStrategiesServicesDetails from "../elements/asco-amplification-strategies/services-slider";
import ASCOWebinarDetails from "../elements/asco-amplification-strategies/webinar-details";
import { ContactSection } from "../elements/contact-section";
import LatestNewsSection from "../elements/latest-news-slider";

function ASCOAmplificationStrategies() {
  return (
    <>
      <ASCOAmplificationStrategiesBanner />
      <ASCOAmplificationStrategiesDetail />
      <ASCOWebinarDetails />
      <ASCOAmplificationStrategiesServicesDetails />

      <LatestNewsSection />
      <ContactSection />
    </>
  );
}

export default ASCOAmplificationStrategies;
