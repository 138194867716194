import React, { Component } from "react";
import { Image } from "react-bootstrap";
import Slider from "react-slick";

import ascoConferenceImg from "../../../images/banner/ASCO-conference.png";

class ASCOAmplificationStrategiesServicesDetails extends Component {
  render() {
    const settings = {
      dots: false,
      overflow: "hidden",

      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 591,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>
        {" "}
        <h1
          style={{
            textAlign: "center",
            marginBottom: "40px",
            marginTop: "40px",
          }}
        >
          Services
        </h1>
        <section className="section-area section-sp1 service-wraper mb-30">
          <div className="row align-items-center">
            <div className="col-xl-12 mb-15">
              <Slider {...settings} className="service-slide slick-arrow-none">
                <ASCOAmplificationStrategiesSlide
                  title="Pre, During and Post Conference Marketing"
                  points={[
                    {
                      subTitle: "Pre Conference:",
                      description: `Digital Marketing:NPI#/CPT/ICD - 10 Code Targeting`,
                    },
                    {
                      subTitle: "During Conference:",
                      description: `ASCO Digital Geofencing`,
                    },
                    {
                      subTitle: "Post Conference:",
                      description: `Retargeting 30 Days after ASCO`,
                    },
                  ]}
                  image={ascoConferenceImg}
                  price="Custom Pricing"
                />
                <ASCOAmplificationStrategiesSlide
                  title="Booth Loop Video"
                  points={[
                    "120-second animated video loop",
                    "Incorporate charts & statistics",
                    "Animated text",
                    "Video footage",
                    "Optional event landing page",
                  ]}
                  image="https://media.graphassets.com/output=format:jpg/zGRkgClDQkuN6dF5miZm"
                  price="$4K-$6K"
                />

                <ASCOAmplificationStrategiesSlide
                  title="Capture Testimonial Videos During The Conference"
                  points={[
                    "2-3 Minute KOL/SME Testimonial Videos",

                    "30 to 60-second KOL/SME Testimonial Videos",

                    "Formal or Trade Show Floor Interviews",
                  ]}
                  image="https://media.graphassets.com/output=format:jpg/resize=height:800,fit:max/9v8lyahkTDOsTm8sAW0g"
                  price="$10K-$20K"
                />
              </Slider>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ASCOAmplificationStrategiesServicesDetails;

const ASCOAmplificationStrategiesSlide = ({ title, points, image, price }) => {
  return (
    <div className="slider-item">
      <div className="feature-container feature-bx2 feature1">
        <div className=" mb-30">
          <Image
            style={{
              width: "100%",
              height: "320px",
              marginBottom: "24px",
              borderRadius: "16px",
              objectFit: "cover",
            }}
            src={image}
            alt={title}
          />
        </div>
        <div className="icon-content">
          <h3
            className="ttr-title"
            style={{
              marginBottom: "24px",
            }}
          >
            {title}
          </h3>
          <ul className="list-mark style2">
            {points?.map((point) => (
              <li
                style={{
                  marginBottom: "8px",
                }}
              >
                {point?.subTitle && (
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      marginBottom: "0px",
                    }}
                  >
                    {point?.subTitle}
                  </p>
                )}
                {point?.description || point}
              </li>
            ))}
          </ul>

          <h3
            style={{
              textAlign: "center",
              marginTop: "40px",
            }}
          >
            {price}{" "}
          </h3>
        </div>
      </div>
    </div>
  );
};
