import React, { Component } from "react";
import { CustomerSection } from "../customersSection";
import VimeoEmbed from "../vimeo/vimeo";

import ferrumHealthLogo from "../../../images/logos/ferrum-health.jpg";
import hologicLogo from "../../../images/logos/hologic.jpg";
import iCadMedicalLogo from "../../../images/logos/i-cad-medical.png";
import RADFormation from "../../../images/logos/rad-ormation.png";
import tempusLogo from "../../../images/logos/tempus.png";

const RSNA_CLIENTS = [
  {
    client1: { logo: tempusLogo, alt: "Tempus AI" },
    client2: { logo: hologicLogo, alt: "Hologic" },
  },
  {
    client1: { logo: iCadMedicalLogo, alt: "iCad Medical" },
    client2: {
      logo: "https://media.graphassets.com/output=format:jpg/resize=height:800,fit:max/ZKOtJtBQ431UgtCWFVFg",
      alt: "Agilent Technologies",
    },
  },
  {
    client1: { logo: RADFormation, alt: "RAD Formation" },
    client2: { logo: ferrumHealthLogo, alt: "Ferrum Health" },
  },
];

// Import Images

class RSNAAmplificationStrategiesBanner extends Component {
  render() {
    return (
      <>
        <div className="conference-hero">
          <div className="container inner-content">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-7">
                <h1> RSNA Amplification Strategies</h1>
                <h4 className="title-ext text-primary">
                  Find out how to maximize your RSNA conference ROI with our
                  proven digital advertising and video strategies
                </h4>
              </div>{" "}
              <div className="col-lg-6 col-md-6 col-sm-5">
                {" "}
                <VimeoEmbed />
              </div>
            </div>
          </div>
        </div>
        <CustomerSection clients={RSNA_CLIENTS} />
      </>
    );
  }
}

export default RSNAAmplificationStrategiesBanner;
