import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// Import Images

import { useContext } from "react";
import { WebinarContext } from "../../../data-service/webinars/webinar-context";
import webinarThumbnail from "../../../images/services/asco-webinar-thumbnail.jpg";

function ASCOWebinarDetails() {
  const webinarContext = useContext(WebinarContext);
  const { selectedWebinar } = webinarContext;

  useEffect(() => {
    webinarContext.fetchWebinarBySlug("unlocking-asco-success");
  });

  if (!selectedWebinar) return <></>;

  return (
    <section className="section-area section-sp1" style={{ paddingBottom: 0 }}>
      <div className="container">
        <div className="text-center mb-50">
          <h2>ASCO Conference Success</h2>
        </div>

        <div className="row">
          <div className="col-md-12 mb-md-50">
            <div className="blog-card blog-single">
              {selectedWebinar !== null ? (
                <>
                  <div>
                    <img
                      style={{
                        width: "100%",
                      }}
                      src={webinarThumbnail}
                      alt="Webinar thumbnail"
                    />
                  </div>

                  {!!selectedWebinar?.description?.length && (
                    <div className="row justify-content-center">
                      <div className="col-lg-7 mt-30">
                        <div className="heading-bx">
                          <p
                            style={{
                              fontSize: 16,
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {selectedWebinar.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <Link
                    to={`/webinars/unlocking-asco-success`}
                    className="btn btn-outline-primary btn-sm"
                    style={{ maxWidth: "250px", margin: "auto" }}
                  >
                    Watch Now
                    <i className="btn-icon-bx fas fa-chevron-right"></i>
                  </Link>
                </>
              ) : (
                <div>loading...</div>
              )}
            </div>

            <div className="clear" id="comment-list">
              <div className="comments-area" id="comments"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ASCOWebinarDetails;
